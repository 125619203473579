<template>
<div class="section">

    <b-modal v-model="isShowPDFDialogLocal" scroll="keep" @close="closePDFDialog">
        <div class="card popupDialog">
            <div class="card-content">
                    <iframe id="pdf" class="pdfiFrame">
                        Your browser doesn't support iframes
                    </iframe> 
            </div>
        </div>
    </b-modal>        

</div>

</template>

<script>

import { PDFDocument, StandardFonts, PageSizes, degrees, grayscale, rgb } from 'pdf-lib'
import moment from 'moment'
import UserStore from "../../../store/UserStore"

export default {
    props: {
        isShowPDFDialog: Boolean,
        groupedInventory: Array,
        productTotals: Array,
        dateRange: Array
    },

    data() {
        return {
            pdfDoc: {},
            helveticaFont: {},
            pageNumber: 0,
            isShowPDFDialogLocal: false,
            page: {},
            dollarUSLocale: Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'})
        }

    },

    methods: {

        closePDFDialog() {
            this.$emit('close-pdf-dialog')
        },

        netClosingVolume(row) {
            return row.startingVolume + row.deliveredVolume - row.soldVolume
        },  

        netClosingVolumePOS(row) {
            return row.beginningVolumePOS + row.deliveredVolumePOS - row.soldVolume
        },

        varianceATG(row) {
            return row.endingVolume - this.netClosingVolume(row)
        },

        variancePOS(row) {
            return row.endingVolumePOS - this.netClosingVolumePOS(row)
        },

        createPdf: async(gthis) => {

            gthis.pdfDoc = await PDFDocument.create()
            // const pdfDoc = gthis.pdfDoc
            gthis.pdfDoc.setAuthor('VEECLi Inc. All rights reserved.')
            gthis.pdfDoc.setTitle('Fuel Inventory Reconciliation')
            gthis.pdfDoc.setSubject('support@veecli.com')
            gthis.pdfDoc.setCreator('VEECLi Inc - www.veecli.com')
            gthis.pdfDoc.setProducer('VEECLi Backoffice Software')

            gthis.helveticaFont = await gthis.pdfDoc.embedFont(StandardFonts.Helvetica)

            var text = ""
            var row = 680
            var totalStart,totalDelivery,totalSold,totalClose,totalEnd,variance = 0

            // var atgDataAvailable = totalSold = gthis.groupedInventory[0].reduce((sum, item) => sum + item.startingVolume,0,)
            // var posDataAvailable = totalSold = gthis.groupedInventory[0].reduce((sum, item) => sum + item.beginningVolumePOS,0,)

            var atgDataAvailable = totalSold = gthis.groupedInventory[0].reduce((sum, item) => sum + item.netCloseVol,0,)
            var posDataAvailable = totalSold = gthis.groupedInventory[0].reduce((sum, item) => sum + item.netCloseVolPOS,0,)

            if (!atgDataAvailable && !posDataAvailable) {
                await gthis.pageHeader(gthis)
                row = await gthis.getNextRow(gthis,row, 20)
                gthis.pageSubHead(gthis, row, "Not enough data available to reconcile fuel inventory!")
            }

            if (atgDataAvailable) {
                for (const prod of gthis.groupedInventory) {
                    await gthis.pageHeader(gthis)
                    text = prod[0].product
                    
                    row = await gthis.getNextRow(gthis,row, 1)
                    gthis.pageSubHead(gthis, row, text)

                    row = await gthis.getNextRow(gthis,row, 6)
                    gthis.drawColHeadLeft(gthis, row, 30, 11, "Date")
                    gthis.drawColHeadRight(gthis, row, 170, 11, "ATG Open")
                    gthis.drawColHeadRight(gthis, row, 260, 11, "ATG Delivery") 
                    gthis.drawColHeadRight(gthis, row, 335, 11, "POS Sales") 
                    gthis.drawColHeadRight(gthis, row, 410, 11, "ATG Balance") 
                    gthis.drawColHeadRight(gthis, row, 485, 11, "ATG Close") 
                    gthis.drawColHeadRight(gthis, row, 575, 11, "ATG Variance") 

                    for (const item of prod) {
                        row = await gthis.getNextRow(gthis,row, 3.5)
                        gthis.drawTextLeft(gthis, row, 30, 10, moment(item.date).format('MM/DD/YYYY'))

                        text = gthis.formatNumberWithCommas(item.startingVolume,3)
                        gthis.drawTextRight(gthis, row, 170, 10, text)

                        text = gthis.formatNumberWithCommas(item.deliveredVolume,3)
                        gthis.drawTextRight(gthis, row, 260, 10, text)

                        text = gthis.formatNumberWithCommas(item.soldVolume,3)
                        gthis.drawTextRight(gthis, row, 335, 10, text)

                        text = gthis.formatNumberWithCommas(gthis.netClosingVolume(item),3)
                        gthis.drawTextRight(gthis, row, 410, 10, text)

                        text = gthis.formatNumberWithCommas(item.endingVolume,3)
                        gthis.drawTextRight(gthis, row, 485, 10, text)

                        text = gthis.formatNumberWithCommas(gthis.varianceATG(item),3)
                        gthis.drawTextRight(gthis, row, 575, 10, text)                                                                                
                    }

                    row = await gthis.getNextRow(gthis,row, 5)

                    gthis.drawTextLeft(gthis, row, 30, 10, "Net")

                    totalStart = prod[0].startingVolume
                    text = gthis.formatNumberWithCommas(totalStart,3)
                    gthis.drawTextRight(gthis, row, 170, 10, text)

                    totalDelivery = prod.reduce((sum, item) => sum + item.deliveredVolume,0,)
                    text = gthis.formatNumberWithCommas(totalDelivery,3)
                    gthis.drawTextRight(gthis, row, 260, 10, text)

                    totalSold = prod.reduce((sum, item) => sum + item.soldVolume,0,)
                    text = gthis.formatNumberWithCommas(totalSold,3)
                    gthis.drawTextRight(gthis, row, 335, 10, text)

                    totalClose = totalStart + totalDelivery - totalSold
                    text = gthis.formatNumberWithCommas(totalClose,3)
                    gthis.drawTextRight(gthis, row, 410, 10, text)

                    totalEnd = prod[prod.length-1].endingVolume
                    text = gthis.formatNumberWithCommas(totalEnd,3)
                    gthis.drawTextRight(gthis, row, 485, 10, text)

                    variance = totalEnd - totalClose
                    text = gthis.formatNumberWithCommas(variance,3)
                    gthis.drawTextRight(gthis, row, 575, 10, text)
                    gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)

                    row = await gthis.getNextRow(gthis,row, 5)
                    gthis.drawTextLeft(gthis, row, 30, 10, "*** Report includes fuel inventory from Veeder Root ATG and fuel sales from Verifone POS")

                    row = 680
                }

            }

            if (posDataAvailable) {
                for (const prod of gthis.groupedInventory) {
                    await gthis.pageHeader(gthis)
                    text = prod[0].product
                    
                    row = await gthis.getNextRow(gthis,row, 1)
                    gthis.pageSubHead(gthis, row, text)

                    row = await gthis.getNextRow(gthis,row, 6)
                    gthis.drawColHeadLeft(gthis, row, 30, 11, "Date")
                    gthis.drawColHeadRight(gthis, row, 170, 11, "POS Open")
                    gthis.drawColHeadRight(gthis, row, 260, 11, "POS Delivery") 
                    gthis.drawColHeadRight(gthis, row, 335, 11, "POS Sales") 
                    gthis.drawColHeadRight(gthis, row, 410, 11, "POS Balance") 
                    gthis.drawColHeadRight(gthis, row, 485, 11, "POS Close") 
                    gthis.drawColHeadRight(gthis, row, 575, 11, "POS Variance") 

                    for (const item of prod) {
                        row = await gthis.getNextRow(gthis,row, 3.5)
                        gthis.drawTextLeft(gthis, row, 30, 10, moment(item.date).format('MM/DD/YYYY'))

                        if (item.previousDayManualEndingVolumePOS) {
                            item.beginningVolumePOS = item.previousDayManualEndingVolumePOS
                        }
                        text = gthis.formatNumberWithCommas(item.beginningVolumePOS,3)
                        gthis.drawTextRight(gthis, row, 170, 10, text)

                        if (item.manualDeliveryVolumePOS) {
                            item.deliveredVolumePOS = item.manualDeliveryVolumePOS
                        }
                        text = gthis.formatNumberWithCommas(item.deliveredVolumePOS,3)
                        gthis.drawTextRight(gthis, row, 260, 10, text)

                        text = gthis.formatNumberWithCommas(item.soldVolume,3)
                        gthis.drawTextRight(gthis, row, 335, 10, text)

                        text = gthis.formatNumberWithCommas(gthis.netClosingVolumePOS(item),3)
                        gthis.drawTextRight(gthis, row, 410, 10, text)

                        if (item.manualEndingVolumePOS) {
                            item.endingVolumePOS = item.manualEndingVolumePOS
                        }

                        text = gthis.formatNumberWithCommas(item.endingVolumePOS,3)
                        gthis.drawTextRight(gthis, row, 485, 10, text)

                        text = gthis.formatNumberWithCommas(gthis.variancePOS(item),3)
                        gthis.drawTextRight(gthis, row, 575, 10, text)                                                                                
                    }

                    row = await gthis.getNextRow(gthis,row, 5)

                    gthis.drawTextLeft(gthis, row, 30, 10, "Net")

                    totalStart = prod[0].beginningVolumePOS
                    text = gthis.formatNumberWithCommas(totalStart,3)
                    gthis.drawTextRight(gthis, row, 170, 10, text)

                    totalDelivery = prod.reduce((sum, item) => sum + item.deliveredVolumePOS,0,)
                    text = gthis.formatNumberWithCommas(totalDelivery,3)
                    gthis.drawTextRight(gthis, row, 260, 10, text)

                    totalSold = prod.reduce((sum, item) => sum + item.soldVolume,0,)
                    text = gthis.formatNumberWithCommas(totalSold,3)
                    gthis.drawTextRight(gthis, row, 335, 10, text)

                    totalClose = totalStart + totalDelivery - totalSold
                    text = gthis.formatNumberWithCommas(totalClose,3)
                    gthis.drawTextRight(gthis, row, 410, 10, text)

                    totalEnd = prod[prod.length-1].endingVolumePOS
                    text = gthis.formatNumberWithCommas(totalEnd,3)
                    gthis.drawTextRight(gthis, row, 485, 10, text)

                    variance = totalEnd - totalClose
                    text = gthis.formatNumberWithCommas(variance,3)
                    gthis.drawTextRight(gthis, row, 575, 10, text)
                    gthis.drawTotalRectangle(gthis, row, 30, 550, 16, 0.1)

                    row = await gthis.getNextRow(gthis,row, 5)
                    gthis.drawTextLeft(gthis, row, 30, 10, "*** Report shows fuel inventory and fuel sales informatin from Verifone POS")

                    row = 680
                }

            }

            // Create PDF Document
            const pdfDataUri = await gthis.pdfDoc.saveAsBase64({ dataUri: true });
            document.getElementById('pdf').src = pdfDataUri;
        },

        pageHeader: async(gthis, titleText)  => {

            if (!titleText) {
                titleText = "FUEL INVENTORY RECONCILIATION"
            }
            gthis.pageNumber++

            gthis.page = gthis.pdfDoc.addPage(PageSizes.Letter);
            
            var storeName = UserStore.getters.getActiveStore.storeName
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(storeName, 14);

            gthis.page.drawText(storeName, { 
                x: 300 - (textWidth/2), 
                y: 750, 
                size: 14, 
                })

            var row = 725
            var reportHead = titleText
            textWidth = gthis.helveticaFont.widthOfTextAtSize(reportHead, 15);

            gthis.page.drawText(reportHead, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 15, 
                })            

            row = 700
            var dateRange = moment(gthis.dateRange[0]).format('MM/DD/YYYY') + " - " + moment(gthis.dateRange[1]).format('MM/DD/YYYY')
            textWidth = gthis.helveticaFont.widthOfTextAtSize(dateRange, 11);

            gthis.page.drawText(dateRange, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 11, 
                })
            await gthis.pageFooter(gthis)                         
                
        },

        pageSubHead: async(gthis, row,subText) => {
            if (!subText) {
                subText = ""
            }
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(subText, 13);

            gthis.page.drawText(subText, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 13, 
                })   

        },

        veecliLogo: async(gthis) => {
            const pngUrl = '/images/veecli-logo.png'
            const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())

            const pngImage = await gthis.pdfDoc.embedPng(pngImageBytes)
            const pngDims = pngImage.scale(0.08)
            
            gthis.page.drawImage(pngImage, {
                x: 300,
                y: 18,
                width: pngDims.width,
                height: pngDims.height,
                rotate: degrees(0),
                opacity: 0.75,
            })
            gthis.page.drawText(" VEECLi.com", { 
                x: 308, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })                    
        },

        pageFooter: async(gthis) => {

            let timeNow = Date()
            timeNow = moment(timeNow).format('MM/DD/YYYY hh:mm a')

            gthis.page.drawText(timeNow, { 
                x: 20, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })

            var textWidth = gthis.helveticaFont.widthOfTextAtSize('Page ' + gthis.pageNumber, 9)
            gthis.page.drawText('Page ' + gthis.pageNumber, { 
                x: 590 - textWidth, 
                y: 20, 
                size: 9,
                TextAlignment: 2
                })
            await gthis.veecliLogo(gthis)
        },

        getNextRow: async(gthis,row, count) => {
  
            if (!count) count = 1
            if (row > 75) {
                return row - (5 * count)
            } else {
                await gthis.pageHeader(gthis)

                // Set Line to start records
                return 680
            }
            
        },

        drawRowHeadTextLeft: async (gthis, row, colStart, fontSize, text) => {
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row, 
                size: fontSize, 
            })
            return row
        },

        drawRowTextLeft: async (gthis, row, text) => {
            row = await gthis.getNextRow(gthis,row, 2)
            // text = text[0].toUpperCase() + text.substr(1).toLowerCase()
            if (text === "InsideSales") { text = "Inside Sales"}
            if (text === "BeerWine") { text = "Beer / Wine"}
            text = gthis.toTitleCase(text)

            gthis.page.drawText(text, { 
                x: 45, 
                y: row, 
                size: 9, 
            })
            return row
        },

        drawTextLeft: async (gthis, row, colStart, fontSize, text) => {
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawTextRight: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawColHeadLeft:async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart, 
                y: row+3, 
                size: fontSize,
                TextAlignment: 2 
            })
            gthis.page.drawLine({
                start: { x: colStart, y: row },
                end: { x: colStart+textWidth+20, y: row  },
                thickness: 1,
                opacity: 0.4,
            })
            
        },

        drawColHeadRight:async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text, fontSize)
            gthis.page.drawText(text, { 
                x: colStart * 1 - (textWidth), 
                y: row+3, 
                size: fontSize,
                TextAlignment: 2 
            })
            gthis.page.drawLine({
                start: { x: colStart - textWidth - 20, y: row },
                end: { x: colStart, y: row  },
                thickness: 1,
                opacity: 0.4,
            })
            
        },

        drawTextRightGal: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(text+" gal.", fontSize)
            gthis.page.drawText(text+" gal.", { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },

        drawTextRightCurrency: async (gthis, row, colStart, fontSize, text) => {
            var textWidth = gthis.helveticaFont.widthOfTextAtSize(gthis.dollarUSLocale.format(text), fontSize)
            gthis.page.drawText(gthis.dollarUSLocale.format(text), { 
                x: colStart * 1 - (textWidth), 
                y: row, 
                size: fontSize,
                TextAlignment: 2 
            })
            
        },        

        drawLine: async (gthis, row) => {
            row = await gthis.getNextRow(gthis,row, 1)
            gthis.page.drawLine({
                start: { x: 45, y: row },
                end: { x: 500, y: row },
                thickness: 1,
                opacity: 0.2,
            })
            return row
        },

        drawTotalRectangle: async (gthis, row, colStart, width, height, opacity) => {
            gthis.page.drawRectangle({
                x: colStart-4,
                y: row-4,
                width: width,
                height: height,
                rotate: degrees(0),
                borderWidth: 0,
                borderColor: grayscale(0.5),
                color: rgb(0, 0, 0),
                opacity: opacity,
                borderOpacity: 0.75,
            })
        },

        calculateTotals() {
                       
            // this.totalFuelSales = this.profitAndLossData.fuelSalesList.reduce((sum, item) => sum + parseFloat(item.saleAmount),0,)
            // this.totalSaleVolume = this.profitAndLossData.fuelSalesList.reduce((sum, item) => sum + parseFloat(item.saleVolume),0,)
            // this.totalFuelCost = this.profitAndLossData.fuelSalesList.reduce((sum, item) => sum + parseFloat(item.costAmount),0,)
            // this.profitAndLossData.merchSalesList = this.profitAndLossData.merchSalesList.filter(item => {
            //     return item.storeMapping != "Fuel"
            // })
            // this.totalMerchSales = this.profitAndLossData.merchSalesList.reduce((sum, item) => sum + parseFloat(item.netSalesValue),0,)
            // this.totalMerchCost = this.profitAndLossData.merchSalesList.reduce((sum, item) => sum + parseFloat(item.cost),0,)
        
            // this.totalOtherIncome = this.profitAndLossData.additionalIncomeList.reduce((sum, item) => sum + parseFloat(item.amount),0,)
            // this.totalRevenue = parseFloat(this.totalFuelSales) + parseFloat(this.totalMerchSales) + parseFloat(this.totalOtherIncome)
            // this.totalCostOfGoodsSold = parseFloat(this.totalMerchCost) + parseFloat(this.totalFuelCost)
            // this.grossProfit = parseFloat(this.totalRevenue) - parseFloat(this.totalCostOfGoodsSold)
            // this.netProfit = parseFloat(this.grossProfit) - parseFloat(this.summaryNumbers.totalOperatingExpense)
            // this.inventoryPurchase = this.profitAndLossData.inventoryExpenseList.filter((inventory) => {
            //     return inventory.deptMapping !== 'Fuel'
            // })
            // this.fuelInventoryPurchase = this.profitAndLossData.inventoryExpenseList.filter((inventory) => {
            //     return inventory.deptMapping === 'Fuel'
            // })
        },

        async loadData() {
            this.isShowPDFDialogLocal = true
            // this.groupedDeliveryByFuel()
            // this.calculateTotals()
            this.createPdf(this)
            this.pageNumber = 0
        }
    },
  
}
</script>


<style scoped>

.popupDialog {
    /* position:absolute; */
    width:100%;
    height: 100%; 
    max-height: 1000px;
    min-height: 900px;     
}

.pdfiFrame {
    position:absolute; 
    top:0; 
    left:0; 
    bottom:0; 
    /* right:0;  */
    width:100%; 
    height:100%; 
    /* border:none; */
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
    /* padding:0;  */
    overflow:hidden; 
    /* z-index:999999;    */
}
div{
    height: 100%;
}
</style>