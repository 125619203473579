<template>
    <div class="section">
        <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowEditDialog }">
            <div class="modal-background"></div>
            <div class="modal-card scrollable" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">Edit Fuel Inventory</p>&nbsp;&nbsp;
                    <button class="delete is-danger" type="button" @click="closeDialog">Cancel</button>
                </header>
                <section class="modal-card-body">
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-3">
                            Date:
                        </div>
                        <div class="column is-3">
                            {{ fuelInvEdit.date | formatDate }}
                        </div> 
                        <div class="column is-3">
                        </div>
                        <div class="column is-3">

                        </div>                     
                    </div>

                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-3">
                            Product:
                        </div>
                        <div class="column is-3">
                            {{ fuelInvEdit.product }}
                        </div> 
                        <div class="column is-3">
                            Sales:
                        </div>
                        <div class="column is-3 has-text-right">
                            {{ formatNumberWithCommas(fuelInvEdit.soldVolume,3)  }}
                        </div>                     
                    </div>

                    <div class="veecli-spacer-row"></div>

                    <div class="columns veecli-table has-background-white-ter is-mobile">
                        <div class="column">
                            Tank Reconciliation from Veeder-Root
                        </div>
                    </div>
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-3">
                            Open:
                        </div>
                        <div class="column is-3">
                            {{ formatNumberWithCommas(fuelInvEdit.startingVolume,3)}}
                        </div>  
                        <div class="column is-3">
                            Delivery:
                        </div>
                        <div class="column is-3 has-text-right">
                            {{ formatNumberWithCommas(fuelInvEdit.deliveredVolume,3)}}
                        </div>                  
                    </div>
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-3">
                            Close:
                        </div>
                        <div class="column is-3 has-text-right">
                                {{ formatNumberWithCommas(fuelInvEdit.endingVolume,3)}}
                        </div>
                        <div class="column is-3">
                            Updated:
                        </div>
                        <div class="column is-3 has-text-right">
                            <VeeInput
                                type="number" 
                                v-model="fuelInvEdit.manualEndingVolume"
                                name="endingVolume"
                                :required="fuelInvEdit.manualEndingVolume == 0"
                                :value="fuelInvEdit.manualEndingVolume"
                                :editable="editable"
                                @focus="$event.target.select()"
                                />
                        </div>     
                    </div>
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-3">
                            Variance:
                        </div>
                        <div class="column is-3 has-text-right"  :class="{'has-text-danger': ATGVariance < 0.00 }">
                            {{ formatNumberWithCommas(ATGVariance,3)}}
                        </div>
                        <div class="column is-3"  v-if="fuelInvEdit.manualEndingVolume">
                            <span v-if="fuelInvEdit.manualEndingVolume">
                                Updated:
                            </span>
                        </div>
                        <div class="column is-3 has-text-right" :class="{'has-text-danger': ATGVarianceUpdated < 0.00 }">
                            <span v-if="fuelInvEdit.manualEndingVolume">
                                {{ formatNumberWithCommas(ATGVarianceUpdated,3)}}
                            </span>  
                        </div>                    
                    </div>

                    <div class="veecli-spacer-row"></div>

                    <div class="columns veecli-table has-background-white-ter is-mobile">
                        <div class="column">
                            Tank Reconciliation from POS
                        </div>
                    </div>
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-3">
                            Delivery:
                        </div>
                        <div class="column is-3 has-text-righ">
                            {{ formatNumberWithCommas(fuelInvEdit.deliveredVolumePOS,3)}}
                        </div>
                        <div class="column is-3">
                            Updated:
                        </div>
                        <div class="column is-3 has-text-righ">
                            <VeeInput
                                type="number" 
                                v-model="fuelInvEdit.manualDeliveryVolumePOS"
                                name="manualDeliveryVolumePOS"
                                :required="fuelInvEdit.manualDeliveryVolumePOS == 0"
                                :value="fuelInvEdit.manualDeliveryVolumePOS"
                                :editable="editable"
                                @focus="$event.target.select()"
                                />
                            
                        </div>                    
                    </div>
                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-3">
                            Close:
                        </div>
                        <div class="column is-3 has-text-righ">
                            {{ formatNumberWithCommas(fuelInvEdit.endingVolumePOS,3)}}
                        </div> 
                        <div class="column is-3">
                            Updated:
                        </div>
                        <div class="column is-3 has-text-righ">
                            <VeeInput
                                type="number" 
                                v-model="fuelInvEdit.manualEndingVolumePOS"
                                name="manualEndingVolumePOS"
                                :required="fuelInvEdit.manualEndingVolumePOS == 0"
                                :value="fuelInvEdit.manualEndingVolumePOS"
                                :editable="editable"
                                @focus="$event.target.select()"
                                />
                        </div>                    
                    </div>

                    <div class="columns is-mobile veecli-input-bottom-border">
                        <div class="column is-3">
                            Variance:
                        </div>
                        <div class="column is-3 has-text-right"  :class="{'has-text-danger': POSVariance < 0.00 }">
                            {{ formatNumberWithCommas(POSVariance,3)}}
                        </div>
                        <div class="column is-3">
                            <span v-if="fuelInvEdit.manualEndingVolumePOS || fuelInvEdit.endingVolumePOS">
                                Updated:
                            </span>
                            
                        </div>
                        <div class="column is-3 has-text-right" :class="{'has-text-danger': POSVarianceUpdated < 0.00}">
                            <span  v-if="fuelInvEdit.manualEndingVolumePOS || fuelInvEdit.manualDeliveryVolumePOS">
                                {{ formatNumberWithCommas(POSVarianceUpdated,3)}}
                            </span>  
                        </div>                    
                    </div>
                    
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" 
                            type="button" 
                            @click="submitForm" 
                            :disabled='isSaveDisabled'>Save</button>
                    <button class="button" type="button" @click="closeDialog">Cancel</button>
                    <span class='is-danger'></span>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>

import VeeInput from "../../../components/app/VeeInput.vue"

export default {
    props: {
        isShowEditDialog: Boolean,
        fuelInvEdit: {},
    },

    components: {
        VeeInput,
    },

    directives: {
        focus: {
            // directive definition
            inserted: function (el) {
                el.focus()
            }
        }
    },

    data() {
        return {
            isPrinting: false,
            showNumberControls: false,
            isMobileNativeDatePicker: false,
            maxClearDate: new Date(),
            isSaveDisabled: false,
            editable: true,
            falseValue: false
        }
    },

    methods: {
        submitForm() {
            this.$emit('save-record')
        },
        closeDialog() {

            this.$buefy.dialog.confirm({
                title: 'Leaving without saving!',
                message: 'Are you sure you want to discard all changes and leave?',
                confirmText: 'Discard Changes',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.$emit('hide-dialog')
                }
            })            

        },
    },

    computed: {
        ATGVarianceUpdated() {
            return this.fuelInvEdit.manualEndingVolume - 
                (this.fuelInvEdit.startingVolume + this.fuelInvEdit.deliveredVolume - this.fuelInvEdit.soldVolume)
        },

        ATGVariance() {
            return this.fuelInvEdit.endingVolume - 
                (this.fuelInvEdit.startingVolume + this.fuelInvEdit.deliveredVolume - this.fuelInvEdit.soldVolume)
        },

        POSVariance() {
            return (this.fuelInvEdit.endingVolumePOS - 
                (this.fuelInvEdit.beginningVolumePOS + this.fuelInvEdit.deliveredVolumePOS - this.fuelInvEdit.soldVolume))
        },

        POSVarianceUpdated() {
            return (this.fuelInvEdit.manualEndingVolumePOS ? this.fuelInvEdit.manualEndingVolumePOS : this.fuelInvEdit.endingVolumePOS) -
            ((this.fuelInvEdit.previousDayManualEndingVolumePOS ? this.fuelInvEdit.previousDayManualEndingVolumePOS : this.fuelInvEdit.beginningVolumePOS) +
                (this.fuelInvEdit.manualDeliveryVolumePOS ? this.fuelInvEdit.manualDeliveryVolumePOS : this.fuelInvEdit.deliveredVolumePOS) -
                this.fuelInvEdit.soldVolume)
        },
    }
}
</script>
    
